<script>
import aclModuleClient from '@/core/acl-module-client';
import Multiselect from 'vue-multiselect';

export default {
  name: 'AclUserGroups',
  components: { Multiselect },
  data: () => ({
    userUUID: null,
    user: {
      loading: true,
      data: { },
    },
    regions: [],
    userGroups: {
      loading: true,
      data: [],
      fields: [
        { key: 'name', label: 'Name' },
        { key: 'region', label: 'Region' },
        { key: 'actions', label: 'Actions' },
      ],
      total: 0,
      items: [],
      filters: {
        page: 1,
        pageSize: 15,
        group_name: null,
        region: null,
      },
      applyFilterBtn: {
        loading: false,
      },
    },
    availableGroups: {
      loading: true,
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Name' },
        { key: 'is_active', label: 'Active' },
        { key: 'selected', label: 'Select' },
      ],
      total: 0,
      paginatedItems: new Map(),
      filters: {
        page: 1,
        pageSize: 15,
        name: '',
        is_active: true,
      },
      applyFilterBtn: {
        loading: false,
      },
      saveBtn: {
        loading: false,
      },
      confirmGroupsBtn: {
        loading: false,
      },
    },
    selectedGroupsModal: {
      loading: false,
      table: {
        loading: false,
        fields: [
          { key: 'id', label: 'ID' },
          { key: 'name', label: 'Name' },
          { key: 'regions', label: 'Regions' },
        ],
        items: [],
      },
    },
    removeGroupModal: {
      group: {},
      confirmBtn: {
        loading: false,
      },
    },
    availableGroupActiveStateFilterOptions: [
      { text: 'Active', value: true },
      { text: 'Inactive', value: false },
      { text: 'All states', value: null },
    ],
  }),
  beforeMount() {
    this.userUUID = this.$route.params.user_uuid;

    return Promise.all([
      this.fetchUser(),
      this.fetchRegions(),
      this.fetchUserGroups(),
      this.changeAvailableGroupsPage(this.availableGroups.filters.page),
    ]);
  },
  computed: {
    isAllSelected: {
      get() {
        return this.availableGroups.paginatedItems.get(this.availableGroups.filters.page)
          .every(role => role.selected);
      },
      set(value) {
        return value;
      },
    },
    userName() {
      return this.user.data?.name ?? 'Unknown user';
    },
    userGroupNameFilterOptions() {
      const uniqueGroupNames = new Set(
        this.userGroups.data.map(g => g.title).sort(),
      );

      return [
        { text: 'All groups', value: null },
        ...Array.from(uniqueGroupNames),
      ];
    },
    userGroupsRegionFilterOptions() {
      const uniqueRegions = new Map();
      this.userGroups.data.forEach(usrGroup => {
        uniqueRegions.set(usrGroup.region_code, {
          text: usrGroup.region_code,
          value: usrGroup.region_id,
        });
      });

      return [
        { text: 'All regions', value: null },
        ...Array.from(uniqueRegions.values()).sort((a, b) => a.text.localeCompare(b.text)),
      ];
    },
  },
  methods: {
    fetchUser() {
      this.user.loading = true;
      return aclModuleClient
        .get(`v1/users/${this.userUUID}`)
        .then(response => {
          this.user.data = response.data;
        })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error loading user');
        })
        .finally(() => {
          this.user.loading = false;
        });
    },
    fetchRegions() {
      return aclModuleClient
        .get('v1/regions')
        .then(response => { this.regions = response.data.data; })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error loading available regions');
        });
    },
    fetchUserGroups() {
      this.userGroups.loading = true;
      return aclModuleClient
        .get(`v1/users/${this.userUUID}/groups`)
        .then(usrGroups => {
          this.userGroups.data = usrGroups.data;
          this.userGroups.total = usrGroups.data.length;
          return this.handleApplyUserGroupsFilters();
        })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error loading user groups');
        })
        .finally(() => {
          this.userGroups.loading = false;
        });
    },
    fetchAvailableGroups() {
      const { filters } = this.availableGroups;
      let search = [];
      if (filters.name !== '') search.push(`title:${filters.name}`);
      if (filters.is_active !== null) search.push(`active:${filters.is_active ? 1 : 0}`);
      search = search.join(',');

      const params = { page: filters.page, limit: filters.pageSize };
      if (search !== '') {
        params.search = search;
      }

      return aclModuleClient
        .get('v1/groups', { params })
        .then(({ data: { data: items, total } }) => {
          const formattedItems = items.map(group => ({
            id: group.id,
            name: group.title,
            is_active: (group.active === 1),
            selected: false,
          }));
          this.availableGroups.paginatedItems.set(
            this.availableGroups.filters.page,
            formattedItems,
          );
          this.availableGroups.total = total;
        });
    },
    changeAvailableGroupsPage(newPage) {
      this.availableGroups.filters.page = newPage;
      this.availableGroups.loading = true;

      const promise = this.availableGroups.paginatedItems.has(this.availableGroups.filters.page)
        ? Promise.resolve()
        : this.fetchAvailableGroups();

      return promise
        .catch(error => {
          console.error(error);
          this.$noty.error('Error loading available groups');
        })
        .finally(() => {
          this.availableGroups.loading = false;
        });
    },
    openRegionsModal() {
      this.availableGroups.saveBtn.loading = true;
      this.selectedGroupsModal.loading = true;
      this.selectedGroupsModal.table.loading = true;

      let selectedGroups = [];
      this.availableGroups.paginatedItems.values().forEach(page => {
        selectedGroups = page.reduce((selected, group) => (
          group.selected
            ? [ ...selected, { ...group, regions: [] } ]
            : selected
        ), selectedGroups);
      });

      if (!selectedGroups.length) {
        this.$noty.info('You must select at least one group to assign to this user.');
        this.availableGroups.saveBtn.loading = false;
        return;
      }

      this.selectedGroupsModal.table.items = selectedGroups;
      this.selectedGroupsModal.table.loading = false;
      this.$refs['modal-groups-regions'].show();
      this.availableGroups.saveBtn.loading = false;
      this.selectedGroupsModal.loading = false;
    },
    handleRemoveGroup(itemToRemove) {
      itemToRemove.loading = true;
      this.removeGroupModal.group = itemToRemove;
      this.$refs['modal-group-remove'].show();
      itemToRemove.loading = false;
    },
    handleRegionsModalHidden() {
      this.selectedGroupsModal.table.items = [];
      this.selectedGroupsModal.loading = true;
      this.selectedGroupsModal.table.loading = true;
    },
    handleApplyToAllGroups(regionsToApply) {
      this.selectedGroupsModal.table.items.forEach(selectedGroup => {
        selectedGroup.regions = regionsToApply;
      });
    },
    handleApplyAvailableGroupsFilters() {
      this.availableGroups.paginatedItems.clear();
      this.availableGroups.filters.page = 1;
      this.changeAvailableGroupsPage(this.availableGroups.filters.page);
    },
    handleApplyUserGroupsFilters() {
      const filterMatchesFn = (fltr, val) => (!(fltr !== null && fltr !== val));
      this.userGroups.items = this.userGroups.data.reduce((items, userGroup) => {
        if (
          filterMatchesFn(this.userGroups.filters.group_name, userGroup.title)
          && filterMatchesFn(this.userGroups.filters.region, userGroup.region_id)
        ) {
          items = [
            ...items,
            {
              name: userGroup.title,
              region: userGroup.region_code,
              group_id: userGroup.id,
              region_id: userGroup.region_id,
              loading: false,
            },
          ];
        }
        return items;
      }, [ ]);
    },
    handleSaveGroups() {
      const hasWithoutRegions = this.selectedGroupsModal.table.items
        .some(group => group.regions.length === 0);

      if (hasWithoutRegions) {
        this.$noty.info('You must select at least one region per group.');
        return Promise.resolve();
      }

      this.availableGroups.applyFilterBtn.loading = true;
      this.availableGroups.saveBtn.loading = true;
      this.availableGroups.confirmGroupsBtn.loading = true;

      const modalHideEventHandler = e => { e.preventDefault(); };
      this.$root.$on('bv::modal::hide', modalHideEventHandler);

      return new Promise(resolve => {
        const userGroups = new Map();
        this.userGroups.data.forEach(usrGroup => {
          userGroups.set(`${usrGroup.id}-${usrGroup.region_id}`, {
            group_id: usrGroup.id,
            region_id: usrGroup.region_id,
          });
        });

        this.selectedGroupsModal.table.items.forEach(selectedGroup => {
          selectedGroup.regions.forEach(region => {
            userGroups.set(`${selectedGroup.id}-${region.id}`, {
              group_id: selectedGroup.id,
              region_id: region.id,
            });
          });
        });

        resolve([ ...userGroups.values() ]);
      })
        .then(userGroups => aclModuleClient.put(`v1/users/${this.userUUID}/groups`, userGroups))
        .then(() => {
          this.$noty.success('User groups successfully saved.');
          this.$root.$off('bv::modal::hide', modalHideEventHandler);
          this.$refs['modal-groups-regions'].hide();

          this.availableGroups.paginatedItems.values().forEach(page => {
            page.forEach(group => { group.selected = false; });
          });

          return this.fetchUserGroups();
        })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error trying to save groups');
          this.$root.$off('bv::modal::hide', modalHideEventHandler);
        })
        .finally(() => {
          this.availableGroups.applyFilterBtn.loading = false;
          this.availableGroups.saveBtn.loading = false;
          this.availableGroups.confirmGroupsBtn.loading = false;
        });
    },
    confirmRemoveGroup() {
      this.removeGroupModal.confirmBtn.loading = true;
      this.removeGroupModal.group.loading = true;

      const modalHideEventHandler = e => { e.preventDefault(); };
      this.$root.$on('bv::modal::hide', modalHideEventHandler);

      const updatedUserGroups = this.userGroups.data
        .reduce((acc, userGroup) => {
          const isChosenToDelete = (
            userGroup.id === this.removeGroupModal.group.group_id
            && userGroup.region_id === this.removeGroupModal.group.region_id
          );

          return isChosenToDelete
            ? acc
            : [ ...acc, { group_id: userGroup.id, region_id: userGroup.region_id } ];
        }, [ ]);

      return aclModuleClient
        .put(`v1/users/${this.userUUID}/groups`, updatedUserGroups)
        .then(() => {
          this.$noty.success('Group was successfully unassigned from this user.');
          this.userGroups.filters.group = null;
          this.userGroups.filters.region = null;
          this.$root.$off('bv::modal::hide', modalHideEventHandler);
          this.$refs['modal-group-remove'].hide();

          return this.fetchUserGroups();
        })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error trying to unassign user\'s group');
          this.$root.$off('bv::modal::hide', modalHideEventHandler);
        })
        .finally(() => {
          this.removeGroupModal.confirmBtn.loading = false;
          this.removeGroupModal.group.loading = false;
        });
    },
    selectAllGroups(isAllSelected) {
      this.availableGroups.paginatedItems.get(this.availableGroups.filters.page).forEach(role => {
        role.selected = isAllSelected;
      });
    },
  },
};
</script>

<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <div class="mt-2 mb-4">
          <h4>
            ACL User Groups -
            <b-spinner v-if="user.loading" class="align-middle"></b-spinner>
            <span v-else>{{ userName }}</span>
          </h4>
        </div>
      </b-col>
    </b-row>

    <b-row cols="1" cols-xl="2">
      <!-- #region User Groups -->
      <b-col class="mb-4">
        <b-card no-body>
          <b-card-header class="d-flex justify-content-between">
            <b-card-title class="m-0 align-content-center">
              User Groups
              <sup
                v-b-tooltip:hover
                title="These are the groups currently assigned to this user.">
                <feather type="help-circle"></feather>
              </sup>
            </b-card-title>

            <div>
              <b-button
                variant="outline-secondary"
                title="Show filters"
                v-b-tooltip:hover
                v-b-toggle.collapse-user-groups-filters>
                Filters
                <feather type="filter"></feather>
              </b-button>
            </div>
          </b-card-header>

          <b-card-body>
            <b-row>
              <b-col>
                <b-collapse id="collapse-user-groups-filters">
                  <b-card class="mb-3" bg-variant="light" no-body>
                    <b-card-body>
                      <b-card-title class="">
                        Filters
                      </b-card-title>

                      <b-form @submit.prevent>
                        <b-form-row>
                          <b-col>
                            <b-form-group id="arf-group-group-name" label="Group name" label-for="arf-group-name">
                              <b-form-select
                                id="arf-group-name"
                                v-model="userGroups.filters.group_name"
                                :options="userGroupNameFilterOptions" />
                            </b-form-group>
                          </b-col>

                          <b-col>
                            <b-form-group id="arf-group-region" label="Region" label-for="arf-region">
                              <b-form-select
                                id="arf-region"
                                v-model="userGroups.filters.region"
                                :options="userGroupsRegionFilterOptions" />
                            </b-form-group>
                          </b-col>
                        </b-form-row>

                        <b-form-row>
                          <b-col class="d-flex justify-content-end">
                            <b-button
                              prevent
                              type="submit"
                              variant="primary"
                              title="Apply filters"
                              v-b-tooltip:hover
                              :disabled="userGroups.loading"
                              @click.prevent="handleApplyUserGroupsFilters">
                              Apply
                              <b-spinner v-if="userGroups.loading" class="align-middle ml-2" small/>
                              <feather v-else type="filter"></feather>
                            </b-button>
                          </b-col>
                        </b-form-row>

                      </b-form>
                    </b-card-body>
                  </b-card>
                </b-collapse>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-table
                  :fields="userGroups.fields"
                  :items="userGroups.items"
                  :busy="userGroups.loading"
                  sticky-header="800px"
                  show-empty
                  hover
                  bordered
                  striped>
                  <template #table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle mr-2"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>

                  <template #empty>
                    <div class="text-center my-2">
                      <strong>No user groups to show</strong>
                    </div>
                  </template>

                  <template #cell(name)="{ item }">
                    <code>{{ item.name }}</code>
                  </template>

                  <template #cell(region)="{ item }">
                    <code>{{ item.region }}</code>
                  </template>

                  <template #cell(actions)="{ item }">
                    <div class="text-center m-0">
                      <b-button
                        v-b-tooltip:hover
                        title="Unassign group from user"
                        size="sm"
                        variant="danger"
                        :disabled="item.loading"
                        @click="handleRemoveGroup(item)">
                        <b-spinner v-if="item.loading" class="align-middle" small/>
                        <feather v-else type="trash-2"></feather>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- #endregion -->

      <!-- #region Available Groups -->
      <b-col>
        <b-card no-body>
          <b-card-header class="d-flex justify-content-between">
            <b-card-title class="m-0 align-content-center">
              Available Groups
              <sup
                v-b-tooltip:hover
                title="These are the available groups that can be assigned to this user.">
                <feather type="help-circle"></feather>
              </sup>
            </b-card-title>

            <div>
              <b-button
                class="mr-4"
                variant="outline-secondary"
                title="Show filters"
                v-b-tooltip:hover
                v-b-toggle.collapse-available-groups-filters>
                Filters
                <feather type="filter"></feather>
              </b-button>

              <b-button
                variant="primary"
                v-b-tooltip:hover
                title="Assign selected groups to this used. You will choose the region for each selected group next."
                :disabled="availableGroups.saveBtn.loading"
                @click="openRegionsModal">
                Save groups
                <b-spinner v-if="availableGroups.saveBtn.loading" class="align-middle ml-2" small/>
                <feather v-else type="save"></feather>
              </b-button>
            </div>
          </b-card-header>

          <b-card-body>
            <b-row>
              <b-col>
                <b-collapse id="collapse-available-groups-filters">
                  <b-card class="mb-3" bg-variant="light" no-body>
                    <b-card-body>
                      <b-card-title class="">
                        Filters
                      </b-card-title>

                      <b-form @submit.prevent>
                        <b-form-row>
                          <b-col>
                            <b-form-group id="arf-group-name" label="Name" label-for="arf-name">
                              <b-form-input
                                id="arf-name"
                                placeholder="Enter group name"
                                v-model="availableGroups.filters.name" />
                            </b-form-group>
                          </b-col>

                          <b-col>
                            <b-form-group id="arf-group-active-state" label="Active state" label-for="arf-active-state">
                              <b-form-select
                                id="arf-active-state"
                                v-model="availableGroups.filters.is_active"
                                :options="availableGroupActiveStateFilterOptions" />
                            </b-form-group>
                          </b-col>

                        </b-form-row>

                        <b-form-row>
                          <b-col class="d-flex justify-content-end">
                            <b-button
                              prevent
                              type="submit"
                              variant="primary"
                              title="Apply filters"
                              v-b-tooltip:hover
                              :disabled="availableGroups.loading"
                              @click.prevent="handleApplyAvailableGroupsFilters">
                              Apply
                              <b-spinner v-if="availableGroups.loading" class="align-middle ml-2" small/>
                              <feather v-else type="filter"></feather>
                            </b-button>
                          </b-col>
                        </b-form-row>

                      </b-form>
                    </b-card-body>
                  </b-card>
                </b-collapse>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-table
                  :fields="availableGroups.fields"
                  :items="availableGroups.paginatedItems.get(availableGroups.filters.page)"
                  :busy="availableGroups.loading"
                  @row-clicked="item => item.is_active && (item.selected = !item.selected)"
                  tbody-tr-class="pointerCursor"
                  show-empty
                  hover
                  bordered
                  striped>

                  <template #table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle mr-2"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>

                  <template #head(selected)>
                    <div class="text-center">
                      <b-form-checkbox v-if="availableGroups?.paginatedItems.has(availableGroups?.filters.page)"
                        v-model="isAllSelected" @change="selectAllGroups" />
                    </div>
                  </template>

                  <template #empty>
                    <div class="text-center my-2">
                      <strong>No available groups to show</strong>
                    </div>
                  </template>

                  <template #cell(name)="{ item }">
                    <code>{{ item.name }}</code>
                  </template>

                  <template #cell(is_active)="{ item }">
                    <div class="text-center">
                      <b-badge v-if="item.is_active" variant="success">Yes</b-badge>
                      <b-badge v-else variant="danger">No</b-badge>
                    </div>
                  </template>

                  <template #cell(selected)="{ item }" >
                    <div class="text-center m-0">
                      <b-form-checkbox
                        :id="`group_${item.id}`"
                        :disabled="!item.is_active"
                        title="This group is not active."
                        v-b-tooltip.hover="{ disabled: item.is_active }"
                        v-model="item.selected"/>
                    </div>
                  </template>

                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-pagination
                  class="my-0"
                  align="center"
                  :value="availableGroups.filters.page"
                  :total-rows="availableGroups.total"
                  :per-page="availableGroups.filters.pageSize"
                  @change="changeAvailableGroupsPage">
                </b-pagination>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- #endregion -->
    </b-row>

    <!-- #region Groups' Regions -->
    <b-modal
      id="modal-groups-regions"
      ref="modal-groups-regions"
      title="Assign groups to user"
      size="max-1000"
      @hidden="handleRegionsModalHidden">

      <b-container class="p-0" fluid>
        <b-row>
          <b-col>
            <b-alert show variant="info">
              Select below at least one region for each group to be assigned to this user.
            </b-alert>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table
              :fields="selectedGroupsModal.table.fields"
              :items="selectedGroupsModal.table.items"
              :busy="selectedGroupsModal.table.loading"
              class="mb-3"
              show-empty
              hover
              outlined
              striped>

              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template #empty>
                <div class="text-center my-2">
                  <strong>No selected groups to show</strong>
                </div>
              </template>

              <template #cell(name)="{ item }">
                <code>{{ item.name }}</code>
              </template>

              <template #cell(regions)="{ item }">
                <div class="d-flex justify-content-between">
                  <multiselect
                    label="code"
                    track-by="id"
                    v-model="item.regions"
                    :placeholder="item.regions.length ? '' : 'Select one or more regions'"
                    :options="regions"
                    :multiple="true"
                    :showLabels="true"/>

                  <div>
                    <b-button
                      class="ml-2"
                      v-b-tooltip:hover
                      title="Apply to all groups."
                      variant="info"
                      v-if="selectedGroupsModal.table.items.length > 1"
                      @click="handleApplyToAllGroups(item.regions)">
                      <feather type="plus-circle"></feather>
                    </b-button>
                  </div>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="secondary"
          :disabled="availableGroups.confirmGroupsBtn.loading"
          @click="cancel()">
          Cancel <feather type="x"></feather>
        </b-button>
        <b-button
          size="sm"
          variant="primary"
          :disabled="availableGroups.confirmGroupsBtn.loading"
          @click="handleSaveGroups">
          Confirm
          <b-spinner v-if="availableGroups.confirmGroupsBtn.loading" class="align-middle ml-2" small/>
          <feather v-else type="check"></feather>
        </b-button>
      </template>
    </b-modal>
    <!-- #endregion -->

    <!-- #region Remove Groups -->
    <b-modal
      id="modal-group-remove"
      ref="modal-group-remove"
      title="Unassign user's group">
      <p>Are you sure you want to unassign the following group from this user?</p>
      <ul>
        <li>Name: <code>{{ removeGroupModal.group.name }}</code></li>
        <li>Region: <code>{{ removeGroupModal.group.region }}</code></li>
      </ul>

      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="secondary"
          :disabled="removeGroupModal.confirmBtn.loading"
          @click="cancel()">
          Cancel
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          :disabled="removeGroupModal.confirmBtn.loading"
          @click="confirmRemoveGroup">
          Confirm
          <b-spinner v-if="removeGroupModal.confirmBtn.loading" class="align-middle ml-2" small/>
          <feather v-else type="trash-2"></feather>
        </b-button>


      </template>
    </b-modal>
    <!-- #endregion -->

  </b-container>
</template>

<style>
/**
 * The following is used on Region selection modal to limit the
 * width of the modal. It is invoked via the `size` prop instead
 * of the `class` attribute: `size="max-1000"`.
 */
div.modal .modal-dialog.modal-max-1000 {
  max-width: 1000px !important;
}

.pointerCursor {
  cursor: pointer !important;
}
</style>
